
<section class="h-100 w-100 gradient-form" style="background-color: black; margin-left: 0%;">
    <div class="container  h-100 w-100" >
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-xl-10">
          <div class="text-black">
            <div class="row g-0">
              <div class="col-lg-6" style="background-color: #333437; color: white;">
                <div class="card-body p-md-5 mx-md-4">
  
                  <div class="text-center" style="height: 100px;">
                   
                  </div>
                  <div class="text-center" >
                    <img (click)="regresar()" src="../../../assets/img/Back.png" style="width: 70px; height: 60px;  cursor: pointer;"> 
                  </div>
                  <div class="text-center" style="height: 100px;">
                   
                  </div>
                  <form [formGroup]="form">
                    <h3>Recuperar contraseña</h3>
                    <p>Ingresa tu correo para recueper contraseña</p>
                    <div class="form-outline mb-4">
                        <input type="text" formControlName="email" placeholder="Email" class="form-control" 
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
                        <span *ngIf="form.controls['email'].errors?.['required'] && form.controls['email'].touched " class="errorMsg">El email no puede ir vacío</span>
                        <span *ngIf="this.form.get('email')?.errors?.['pattern']" class="errorMsg">El email no es válido</span>
                    </div>
                    <div class="text-center pt-1 mb-5 pb-1">
                      <button  [disabled]="!form.valid ? true:false" 
                        class="btn btn-primary btn-lg btn-block" style="width: 100%;" (click)="recoveryPass()"  type="button">Enviar</button>
                    </div>
                  </form>
  
                </div>
              </div>
              <div class="col-lg-6 d-flex align-items-center gradient-custom-2" style="background-image: url(../../../assets/img/logos/login.jpg); background-size: 100% 100%;  ">
                <div class="text-white px-3 py-4 p-md-5 mx-md-4">
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>