import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { ConsumeService } from 'src/app/services/consume.service';
import { SettingsService } from 'src/app/services/settings.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import Swal from 'sweetalert2';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  responsive = true;
  cols = 10;
  LISTA_CARPETAS:string []=[];
  currentUser:        Subject<string[]>=new Subject();
  //
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date ;
  constructor(private service: SettingsService,private consume:ConsumeService,
              private ref: ChangeDetectorRef,private router: Router,
              private idle: Idle, private keepalive: Keepalive,
              private userService:UserService  ) { 

              console.log("H O M E - C O M P O N E N T");
              
              this.loadListCarpetas();

              
  }

  ngOnInit(): void {
    
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }
  
  finish(){
    this.idle.stop();
  }

  selectedFolder(carpeta:any){
      console.log("carpeta:["+carpeta+"]");
      this.service.routerAdmin("principal/"+carpeta,2);
      this.ref.detectChanges();
      this.router.navigate(["principal/"+carpeta]);
  }

  
 public loadListCarpetas() { 
    console.log(":::::: Paso 1 -- INIT loadListCarpetas ::::::");
    let arrayT:string []=[];
    return this.consume.getLstCarpetas().subscribe((response)=>{
      console.log("response:::>"+response.arrayFolders);
      if(response.mensaje.codigo ===200){
        const array:string= response.arrayFolders
        for(let a =0; a < array.length;a++){
          console.log("array:["+array[a]+"]");
          arrayT.push(array[a]);
        }
      } 
      this.LISTA_CARPETAS= arrayT;
      //this.currentUser.next(arrayT);
      localStorage.removeItem("rutap");
      this.ref.detectChanges();
    });
    console.log("::::::Paso 1 FINISH :::::::::::::::::::::");
} 



}

