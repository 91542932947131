
<div class="container" style="margin-left: 800px;margin-top: 250px;">
    <div class="row">
        <div class="col-12">
            <div class="modal-body container-modal" >
                <img class="important-icon" src="../assets/img/important-img.png" align="center" style="width: 200px; height: 200px; align-content: center;">
                <h3 class="modal-title-gray" style="color: red;"> !Pagina invalida¡</h3> 
            </div>
        </div>
    </div>
</div>