<div class="d-flex justify-content-center" >
  <div *ngIf="isLoading" class="loading" style="width: 100px;height:100px;" role="status" >
    <span class="">Cargando espere por favor...</span>
  </div>
</div>
  

<!-- 
<div class="col-lg-12" >
  <div class="row">
      <div class="col-lg-12 azul">
          <button class="btn btn-outline-primary btn-lg" style="width: 20%;" data-toggle="regresar" data-target="#regresar"><i style="font-size: 20px;"></i>
                  <img (click)="regresar()" src="../../../assets/img/prev.png" style="width: 40px; height: 40px;"> 
          </button>
      </div>
  </div>
</div>
-->
<div class="row"  *ngIf="prod">
    <table id="tabla1" mat-table [dataSource]="dataSource"  class="table  tabla-resumen table-striped table-hover table-condensed margenTables" #tabla1>
      <thead class="table-light">
          <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table  justify-content-center text-center" > </tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index" (click)="selection.toggle(row)"></tr>
      </thead>
      <tbody class="table-group-divider" >
        <tr>
          <ng-container matColumnDef="index">
            <th mat-header-cell *matHeaderCellDef class="table-title">NÚM. DE ARCHIVO</th>
            <td mat-cell *matCellDef="let element; let i = index;" class="table-dato border-left-none">{{i +  1 }}</td>
          </ng-container>

          <ng-container matColumnDef="file">
                <th mat-header-cell *matHeaderCellDef class="table-title">NOMBRE DEL ARCHIVO</th>
                <td mat-cell *matCellDef="let element" class="table-dato border-left-none">{{ element.fileName}}    
                </td>
          </ng-container>

          <ng-container matColumnDef="pdf" >
            <th  mat-header-cell *matHeaderCellDef class="table-title"> ABRIR </th>
            <td  mat-cell *matCellDef="let element" class="table-dato border-left-none">
              <button id="btnInvoicePdf" class="btn button-pdf">
                <img (click)="getFile(element,dialogTemplate)" src="../../../assets/img/activos/img.png" style="width: 50px; height: 50px;">
                </button>
               
            </td>
          </ng-container>
        
           <!-- Checkbox Column -->
            <!-- Checkbox Column -->

            <ng-container matColumnDef="select" *ngIf="user" >
                
                  <th mat-header-cell *matHeaderCellDef class="table-title"> 
                    <mat-checkbox  (change)="$event ? masterToggle() : null"
                                  [checked]="selection.hasValue() && isAllSelected()"
                                  [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                  </th>
                  
                  <td mat-cell *matCellDef="let element" class="table-dato border-left-none" style="margin-left: 500px;" >
                    <mat-checkbox style="margin-left: 7px;" (click)="$event.stopPropagation()"
                                  (change)="$event ? selection.toggle(element) : null"
                                  [checked]="selection.isSelected(element)">
                    </mat-checkbox>
                  </td>
  
            </ng-container>
  
             <ng-container matColumnDef="select" *ngIf="!user" >
                  
                  <th mat-header-cell *matHeaderCellDef class="table-title"  >  
                    <mat-checkbox style="display: none;"  (change)="$event ? masterToggle() : null"
                                  [checked]="selection.hasValue() && isAllSelected()"
                                  [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                  </th>
                  
                  <td mat-cell *matCellDef="let element" class="table-dato border-left-none" >
                    <mat-checkbox style="display: none;" (click)="$event.stopPropagation()"
                                  (change)="$event ? selection.toggle(element) : null"
                                  [checked]="selection.isSelected(element)">
                    </mat-checkbox>
                  </td>
  
            </ng-container>
  
      </tr>
      </tbody>
    </table>
   
</div>
<br>
<div class="pagination justify-content-center" style="font-size: 20px;font-weight: 600;">
  
  <mat-paginator color="warn" #paginator  [pageSize]="5" [length]="length" showFirstLastButtons ></mat-paginator>
  
</div>
<div class="row row-cols-11 row-cols-md-7 g-8 " style="font-size: large;" *ngIf="!prod" (ngModel)="prod">
    No exiten registros
</div>

<!--
<mat-grid-list [cols]="10" gutterSize="10px" rowHeight="3:2">
  <mat-grid-tile *ngFor="let item of listFiles2">
    <img class="gallery-small-image"
         [src]="item.imagen"
         [alt]="item.fileName"
         (click)="getFile(item,dialogTemplate)" 
      >
  </mat-grid-tile>
</mat-grid-list>



<div *ngIf="_listImgExist">
  <ngb-carousel style="width: 100%; height: 100%;"
    #carousel
    [interval]="3000"
    [pauseOnHover]="pauseOnHover"
    [pauseOnFocus]="pauseOnFocus"
    (slide)="onSlide($event)"
  >
    <ng-template ngbSlide *ngFor="let img of listFiles2; index as i" style="width: 100%; height: 100%; align-content: center;">
      <div class="carousel-caption">
        <h3># {{i + 1}} - {{img.fileName}}</h3>
      </div>
        <div class="picsum-img-wrapper " style="margin-left: 200px;">
          <img [src]="img.imagen" alt="My image {{i + 1}} description" (click)="getFile(img,dialogTemplate)"  style="height: 500px; width: 900px;"  />
        </div>
    </ng-template>
  </ngb-carousel>

  <hr />
  <div class="row row-cols-11 row-cols-md-7 g-8 " style="font-size: large;">
  <div class="form-check">
    <input type="checkbox" class="form-check-input" id="pauseOnHover" [(ngModel)]="pauseOnHover" />
    <label class="form-check-label" for="pauseOnHover">Pausar al pasar el mouse</label>
  </div>
  <div class="form-check">
    <input type="checkbox" class="form-check-input" id="pauseOnFocus" [(ngModel)]="pauseOnFocus" />
    <label class="form-check-label" for="pauseOnFocus">Pausar en el enfoque</label>
  </div>
  <div class="form-check">
    <input type="checkbox" class="form-check-input" id="unpauseOnArrow" [(ngModel)]="unpauseOnArrow" />
    <label class="form-check-label" for="unpauseOnArrow">Reanudar la pausa al hacer clic en las flechas</label>
  </div>
  <div class="form-check">
    <input type="checkbox" class="form-check-input" id="pauseOnIndicator" [(ngModel)]="pauseOnIndicator" />
    <label class="form-check-label" for="pauseOnIndicator">Pausa al hacer clic en el indicador de navegación.</label>
  </div>
  <button type="button" (click)="togglePaused()" class="btn btn-primary">
    {{paused ? 'Ciclar' : 'Pausar' }}
  </button>
  </div>
</div>


-->

  

<!--


<div class="container" style="width: 20%; height: 20%;" >
    <img src="{{img+1}}" style="width: 40%; height: 40%;" />
    <img src="{{img+2}}" />
    <img src="{{img+3}}" />
    <img src="{{img+4}}" />
    <img src="{{img+5}}" />
    <img src="{{img+6}}" />
</div>
-->

<ng-template  #dialogTemplate >
  <img class="mat-dialog-content-img" src="{{imagen}}" >
  <mat-dialog-actions align="end">
      <div class="modal-footer">
          <button  class="btn btn-secondary" style="width: 100px;" (click)="descargarImagen('{{imagen}}')"  mat-button >Descargar</button>
          <button  class="btn btn-secondary" (click)="closeModalService()"  mat-button >Close</button>
      </div>
  </mat-dialog-actions>
</ng-template>

