import { Component, Inject, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FileTo } from '../pdf/pdf.component';
import { DOCUMENT } from '@angular/common';
import { ConsumeService } from 'src/app/services/consume.service';
import { NgbModal, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog, MatDialogConfig,MatDialogRef } from '@angular/material/dialog';
import { NgbCarousel, NgbCarouselModule, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import * as saveAs from 'file-saver';
import { SelectionModel } from '@angular/cdk/collections';
import { RequestFile } from 'src/app/model/requestFile';

@Component({
  selector: 'app-imagenes',
  templateUrl: './imagenes.component.html',
  styleUrls: ['./imagenes.component.css']
})
export class ImagenesComponent implements OnInit {

  //img = 'https://thecatapi.com/api/images/get?format=src&type=gif?results_per_page=';

  dataSource = new MatTableDataSource<FileTo>();
  dataSourceFile = new MatTableDataSource<FileTo>();
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  displayedColumns: string[] = ['index','file','pdf','select'];
  //displayedColumns: string[] = ['index','file','pdf','borrar','editar'];
  length:number=0;
  prod: boolean=false;
  listFiles: FileTo[]=[];
  listFiles2: FileTo[]=[];
  formData: FormData | undefined;
  imagen: any;
  responsiveOptions: any[] =[];
  //
  paused = false;
	unpauseOnArrow = false;
	pauseOnIndicator = false;
	pauseOnHover = true;
	pauseOnFocus = true;
  isLoading : boolean = false;
  _listImgExist : boolean = false;  
  _pathImagen="imagenes";
  imagenBlob: any;
  nombreImagen="";
  pathHash;
  @ViewChild('carousel', { static: true }) carousel!: NgbCarousel;
  clickedRows = new Set<FileTo>();
  selection = new SelectionModel<FileTo>(true, []);
  fileRequest!: RequestFile;
  user!:boolean;
  constructor(private consumeService: ConsumeService, @Inject(DOCUMENT) document: any,private modalService: NgbModal,
              private router: Router, private userService:UserService) { 
    
        this.pathHash=document.location.hash.substring(1,document.location.hash.length);
        console.log("pathHash:["+this.pathHash+"]");
        this.getListFilesNames();
        this.userService.setCantUploadFile(true);     
        
        this.userService._updateTable.subscribe(updateT=>{
            console.log("updateTable=>"+updateT);
            if(updateT){
                this.getListFilesNames();
            }
        });

        if(localStorage.getItem("rol") !==null && localStorage.getItem("rol") === "ADMIN"){
          this.user=true;
        }

  }

  ngOnInit(): void {
   // window.open("https://drive.google.com/drive/u/1/folders/1RDKNxmBZCBrdwbaNfC2JVEVsgvyDX-wa", '_blank');
   this.paginator._intl.itemsPerPageLabel="Registros por pagina: ";
   this.paginator._intl.nextPageLabel="";
   this.paginator._intl.lastPageLabel="";
   this.paginator._intl.firstPageLabel="";
   this.paginator._intl.previousPageLabel="";

  }

  
  ngAfterViewChecked(): void {
    //console.log("selection :["+this.selection+"]"); 
    console.log("------- ngAfterViewChecked ---------");
    this.fileRequest= new RequestFile;
    let fileSelect:string []=[];
    for (let item of this.selection.selected) {
      console.log(item.fileName);
      fileSelect.push(item.fileName);
    }
    this.fileRequest.path=this.pathHash;
    this.fileRequest.lstFilesname=fileSelect;
    this.userService.setFileRequest(this.fileRequest);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  togglePaused() {
		if (this.paused) {
			this.carousel.cycle();
		} else {
			this.carousel.pause();
		}
		this.paused = !this.paused;
	}

	onSlide(slideEvent: NgbSlideEvent) {
		if (
			this.unpauseOnArrow &&
			slideEvent.paused &&
			(slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)
		) {
			this.togglePaused();
		}
		if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
			this.togglePaused();
		}
	}

  abrirDialog(templateRef:TemplateRef<any>) {
    const dialog = this.modalService.open(templateRef,{
       size: 'lg', 
       windowClass: 'modal-xl',
       centered: true,
       backdrop:'static'
     });
 }
 
 getSeverity(status: string) {
  switch (status) {
      case 'INSTOCK':
          return 'success';
      case 'LOWSTOCK':
          return 'warning';
      case 'OUTOFSTOCK':
          return 'danger';
  }
  return "";
}
  getListFilesNames(){
    this.isLoading=true;
    this.formData = new FormData();
    this.formData?.append("path",this.pathHash);
    this.consumeService.getListFilesNames(this.formData).subscribe((response)=>{
      console.log("response:["+response);
      try {
        this.isLoading=false;
        this.prod=true;
        this.listFiles=response.lstFiles;   
        this.dataSource = new MatTableDataSource(this.listFiles);
        this.dataSource.paginator = this.paginator;
        this.length=response.lstFiles.length;
        this.dataSource.data= this.dataSource.data;
      } catch (error) {
        console.log(error);
      }  
    });
  }


  getListFiles(){
    this.isLoading=true;
    console.log("path:["+document.location.pathname)+"]";
    this.formData = new FormData();
    this.formData?.append("path",document.location.pathname);
    this.consumeService.getListFiles(this.formData).subscribe((response)=>{
      console.log("response:["+response);
      try {
          this.isLoading=false;
          this._listImgExist=true;
          this.prod=true;
          this.listFiles2=response.lstFiles;   
          this.listFiles2.forEach(file=>{
            file.imagen= `data:image/png;base64,${file.file}`;
          });
      } catch (error) {
        console.log(error);
      }  
    });
  }



  getFile(file:any,templateRef:TemplateRef<any>){
    this.isLoading=true;
    console.log("file:["+file.fileName)+"]";
    this.formData = new FormData();
    this.formData?.append("path",this.pathHash);
    this.formData?.append("filename",file.fileName);
    console.log(" this.formData:["+ this.formData);
    this.consumeService.getFileByName(this.formData).subscribe((response)=>{
      console.log("response:["+response);
      try {
        console.log("response.fileName :["+response.lstFiles[0].fileName);
        this.imagenBlob = new Blob([this.convertBase64ToBlob(response.lstFiles[0].file)], { type: 'application/img' });
        this.imagen= `data:image/png;base64,${response.lstFiles[0].file}`;
        this.nombreImagen=response.lstFiles[0].fileName;
        //response.lstFiles[0].imagen=imagen;
        //const nameFile = response.fileTO[0].fileName;
        //saveAs(blob, nameFile);
        //abrir en  navegador el archivo
        //const url = URL.createObjectURL(blob);
        //window.open(url, '_blank');
        this.isLoading=false;
        const dialog = this.modalService.open(templateRef,{
          size: 'lg', //'sm' | 'md' | 'lg' = 'md'
          windowClass: 'modal-lg',
          centered: true,
          backdrop:'static'
        });

      } catch (error) {
        console.log(error);
      }  
    });
  }


  convertBase64ToBlob(Base64Image: any) {
    const decodedData = window.atob(Base64Image);
    const uInt8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    return uInt8Array
  }

  regresar(){
    console.log("pathGeneral:["+document.location.pathname)+"]";
    let pathReplace=document.location.pathname.replace("imagenes","");
    console.log("pathReplace:["+pathReplace)+"]";
    this.userService.getStatusFolder("imagenes");
    this.router.navigate([pathReplace]);
  }

  closeModalService(){
     this.modalService.dismissAll();
  }

  descargarImagen(imagen:any){
    saveAs(this.imagenBlob, this.nombreImagen);
  }

}
