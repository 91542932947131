import { Inject, Injectable, Injector } from '@angular/core';
import { ConsumeService } from './consume.service';
import { SigninRequest } from '../model/SigninRequest';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Usuario } from '../model/usuario';
import { SettingsService } from './settings.service';
import { DOCUMENT } from '@angular/common';
import { RequestFile } from '../model/requestFile';
import { MensajeResponse } from '../model/mensajeResponse';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public  currentUser:        Subject<Usuario>=new Subject();
  public  currentUserSubject: BehaviorSubject<Usuario>;
  public  mediaFolder:        Subject<string>=new Subject();
  public  sendCorreo:         Subject<boolean>=new Subject();
  public  _isPathLogin:       Subject<boolean>=new Subject();
  public  _cantUploadFile:    Subject<boolean>=new Subject();
  public  _updateTable:       Subject<boolean>=new Subject();
  public  _requestFile:       Subject<RequestFile>=new Subject();
  public  _isLogin:           Subject<boolean>=new Subject();
  public  _mensajeResponse:   Subject<MensajeResponse>=new Subject();

  constructor(private consume:ConsumeService,
              private router: Router,
              private injector: Injector,
              private setting:SettingsService,
              @Inject(DOCUMENT) document: any) { 

              this.currentUserSubject = new BehaviorSubject<Usuario>(JSON.parse(localStorage.getItem("usuario")!));
              this.currentUser.next(this.currentUserSubject.value); 
  }

  public login(signinRequest: SigninRequest){
    console.log("========== [login] ==========");
      this.consume.signin(signinRequest).subscribe((response)=>{
          console.log(response);
          if(response.mensaje.codigo ===  200){
              localStorage.setItem("nombre",response.userData.nombre+" "+ response.userData.apellidos);
              localStorage.setItem("rol",response.userData.rol);
              console.log(localStorage.getItem("nombre"));
              console.log(localStorage.getItem("rol"));

              localStorage.setItem("usuario", JSON.stringify(response.userData));
              this.currentUserSubject.next(response.userData);
              this.currentUser.next(this.currentUserSubject.value);

              const router = this.injector.get(Router);
              
              this.setting.routerAdmin("principal",1);

              this.router.navigate(['./principal']);

              this.setPathLogin(false);

              this._isLogin.next(true);
          }else{
              this._isLogin.next(false);
          }
      });
      console.log(" [login] : "+this._isLogin);
  }

  
  getgetRecoveryPass(email: string){
    console.log("========== [login] ==========");
      this.consume.getRecoveryPass(email).subscribe((response)=>{
          console.log(response);
          let mensaj: MensajeResponse= new MensajeResponse();
          mensaj=response.mensaje;
          this._mensajeResponse.next(mensaj);
          if(response.mensaje.codigo ===  200){
              console.log("========== [envio ok ] ==========");
          }else{
            console.log("========== [envio False ] ==========");
          }
      });
  }

  get isAuthenticated(): boolean {
    console.log("========== [isAuthenticated] ==========");
    console.log(localStorage.getItem("rol"));
    let _isAuth=false;
    if(localStorage.getItem("rol") !==null && localStorage.getItem("rol") === "ADMIN"){
      _isAuth=true;
    }
    return _isAuth;
  }

  logout(){
    console.log("========== [logout] ==========");    
    this.currentUser.next(new Usuario);
    localStorage.clear();
    this.router.navigate(['./login']);

  }

  getRol(){
    return localStorage.getItem("rol");
  }

  getStatusFolder(carpeta:string){
      this.mediaFolder.next(carpeta);
  }

  getStatusUser(): Subject<Usuario>{
    return this.currentUser;
  }

  redirectRecoveryPass(){
    console.log("========== [gerRecoveryPass] ==========");  
    this.router.navigate(['recovery']);
  }

  setPathLogin(isPathLogin:boolean){
    this._isPathLogin.next(isPathLogin);
  }

  setCantUploadFile(cantUploadFile:boolean){
    this._cantUploadFile.next(cantUploadFile);
  }

  setFileRequest(fileReq:RequestFile){
      this._requestFile.next(fileReq);
  }

  setUpdateTable(update: boolean){
      this._updateTable.next(update);
  }

}
