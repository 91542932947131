import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(private http:HttpClient) { } 
  
  public getSiteMapUrls(): Observable<any> { 
  //let endpointUrl = `${environment.apiUrl}/menu/sitemap` 
    let endpointUrl = `${environment.uri._hostPortalVisorService}/usuarios/auth/getUserTest`;
    console.log(endpointUrl);
    return this.http.get<any>(endpointUrl); 
  } 

  public getSiteMapUrls2(): Observable<any> { 
    //let endpointUrl = `${environment.apiUrl}/menu/sitemap` 
      //let endpointUrl = `${environment.uri._hostPortalClienteService}/menu/sitemap` 
      let map="{ path: papitas, component: UserComponent,  children:MEDIA_ROUTES }";
      return new Observable((observer) => {
        return observer.next(map);
    });
      //return JSON.stringify(map); 
    } 

}
