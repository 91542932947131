import { Component, Inject, Injector, TemplateRef, ViewChild } from '@angular/core';
import { UserService } from './services/user.service';
import { Usuario } from './model/usuario';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FilesService } from './services/files.service';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ConsumeService } from './services/consume.service';
import { throwError } from 'rxjs';
import { RequestFile } from './model/requestFile';
import { Keepalive } from '@ng-idle/keepalive';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'visor-web';
    isLoading=false;
    formData: FormData =new FormData();
    currentUser!: Usuario;
    _isAdmin=false;
    _isPathLogin=false;
    _canUploadFile=false;
    pathHash:any;
    productoForm: FormGroup;
    limitSizeFile=1024000 * 10;
    fileReq!:RequestFile;
    //
    idleState = 'Not started.';
    timedOut = false;
    lastPing?: Date ;
    screenSize = '';
    isHandset = false;
    isWeb = false;  

    allowedImages = ['jpeg', 'jpg', 'png'];
    allowedDocs = ['pdf'];
    allowedVideos = ['mp4', 'avi', 'mov'];

    constructor(private userService: UserService,private modalService: NgbModal,
               private formBuilder: FormBuilder,private filesService:FilesService,
               @Inject(DOCUMENT) document: any, private injector: Injector,
               private router: Router,private consume:ConsumeService,
               private idle: Idle, private keepalive: Keepalive,
               private breakpointObserver: BreakpointObserver  ){

        console.log("=== AppComponent constructor ====");   
      
       
       
        this.userService.currentUser.subscribe((user: Usuario) => {
              console.log("|||||| actualizando currentUser:["+user+"]  AppComponent ||||||");
              if(user.rol !== 'undefined' && user.rol === "ADMIN"){
                this._isAdmin=true;
              }else{
                this._isAdmin=false;
              }
              this.currentUser=user;
              console.log("||||||||||||||||||||||||||||||||||||||||||||||");
        });

        this.userService._isPathLogin.subscribe(isPathLogin=>{
          this._isPathLogin=isPathLogin;
          console.log("===  isPathLogin:["+this._isPathLogin+"] ===");
        });

        this.userService._cantUploadFile.subscribe(canUploadFile=>{
            this._canUploadFile=canUploadFile;
            console.log("===  canUploadFile:["+this._canUploadFile+"] ===");
        });

        this.userService._requestFile.subscribe(fileRequest=>{
            this.fileReq=fileRequest;
            console.log("===  fileRequest: path:"+this.fileReq.path+" files:["+this.fileReq.lstFilesname+"] ===");
        });

        const rol=localStorage.getItem("rol");
        if(rol === "ADMIN"){
            this._isAdmin=true;
        }
      
        this.productoForm = this.formBuilder.group({
          archivo: new FormControl(null,[Validators.nullValidator])
        });
          
        console.log(" ########  this._isAdmin ########:["+ this._isAdmin+"]");
        console.log(" ########  this._isPathLogin ########:["+this._isPathLogin+"]");
  }

  ngOnInit() {

    this.breakpointObserver.observe([
      Breakpoints.Web,
      Breakpoints.Handset])
      .subscribe(result => {

        const breakpoints = result.breakpoints;

        if(result.matches) {
          console.log('matches');
          this.isHandset = false;
          this.isWeb = false;
          this.screenSize = '';

          if (breakpoints[Breakpoints.WebPortrait] || breakpoints[Breakpoints.WebLandscape]) {
            console.log('screens matches Web');
            this.screenSize = 'Web';
            this.isWeb = true;
          }else if(breakpoints[Breakpoints.HandsetPortrait] || breakpoints[Breakpoints.HandsetLandscape]) {
            console.log('screens matches Handset');
            this.screenSize = 'Handset';
            this.isHandset = true;
          }

        }

      });
    
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }



  onSubmit(){ 
    if(this.formData.get("uploadFile")){

        this.isLoading=true;
        this.consume.putFile(this.formData).subscribe(response=>{
            this.isLoading=false;
            console.log("response: ["+response+"]"); 
            if(response.mensaje.codigo==200){
                this.userService.setUpdateTable(true);
                Swal.fire({
                  title: "¡Felicidades!",
                  text: response.mensaje.mensaje,
                  icon: "success",
                  confirmButtonColor: '#014FA2',
                });
            }else{
                Swal.fire({
                  title: "Upss!",
                  text: response.mensaje.mensaje,
                  icon: "warning",
                  confirmButtonColor: '#014FA2',
                });
            } 
        }, error => {
          Swal.fire({
            title: "Upss!",
            text: "Ocurrio un error favor de validar mas tarde.",
            icon: "warning",
            confirmButtonColor: '#014FA2',
          });
          this.isLoading=false;
          this.handleError(error);
        },);


    }else{
      Swal.fire({
        title: "Atención",
        text: "Debes agregar un archivo.",
        icon: "warning",
        confirmButtonColor: '#014FA2',
      });
    }
    this.closeModalService();
    this.clearForm();
    this.formData.delete('uploadFile');
    this.formData.delete('path');
  }



  logout(){
      this.userService.logout();
  }

  openModalFile(templateRef:TemplateRef<any>){
    const dialog = this.modalService.open(templateRef,{
      size: 'lg', 
      windowClass: 'modal-xl',
      centered: true,
      backdrop:'static'
    });
  }

  deleteFile(){
    this.isLoading=true;
    if(this.fileReq.lstFilesname.length > 0){
      Swal.fire({
        title: "¡Confirmación!",
        text:  "¿ Desea Eliminar los Archivos ?",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Eliminar",
        denyButtonText: `No eliminar`,
        icon: "warning",
        confirmButtonColor: '#014FA2',
      }).then((result) => {
        if (result.isConfirmed) {
            this.consume.deleteFile(this.fileReq).subscribe(response=>{
                this.isLoading=false;
                if(response.mensaje.codigo == 200){
                    let currentUrl = this.router.url;
                    this.userService.setUpdateTable(true);
                    Swal.fire({
                      title: "¡Felicidades!",
                      text: "Se elimino el archivo correctamente.",
                      icon: "success",
                      confirmButtonColor: '#014FA2',
                    });
                }else{
                  Swal.fire({
                    title: "Upss!",
                    text: "No se pudo eliminar",
                    icon: "warning",
                    confirmButtonColor: '#014FA2',
                  });
                } 
            },error => {
              Swal.fire({
                title: "Upss!",
                text: "Ocurrio un error favor de validar mas tarde.",
                icon: "warning",
                confirmButtonColor: '#014FA2',
              });
              this.isLoading=false;
              this.handleError(error);
            },);
        } else if (result.isDenied) {
           this.isLoading=false;
            Swal.fire({
              title:"Eliminación cancelada.",
              text: "",
              icon: "info",
              confirmButtonColor: '#014FA2',
            });
        }else if (result.isDismissed) {
            this.isLoading=false;
            Swal.fire({
              title:"Eliminación cancelada.",
              text: "",
              icon: "info",
              confirmButtonColor: '#014FA2',
            });
        }
      });
    }else{
      this.isLoading=false;
      Swal.fire({
        title:"Debes seleccionar un archivo.",
        text: "",
        icon: "warning",
        confirmButtonColor: '#014FA2',
      });
    }
  }

  onFileSelected(event:any) {
    const files:File[] = event.target.files;
    this.pathHash= this.router.url;
    console.log("files size:["+files.length+"]");
    console.log("pathHash:["+ this.pathHash+"]");
    
    const bytes=1024000;
    Array.from(files).forEach(f =>{
      console.log("Name File:["+f.name+"] ");
      console.log("Size File:["+f.size+"] bytes");
      console.log("Limite File:["+this.limitSizeFile+"] bytes");
      if(f.size > this.limitSizeFile){
          Swal.fire({
            title: "Atención",
            text: "EL limite del archivo no debe de exceder los 10MB.",
            icon: "warning",
            confirmButtonColor: '#014FA2',
          });
          this.clearForm();
      }else{
          if(this.validateExt(f)){
            this.formData.append('uploadFile',f)
          }
      }
    });
    this.formData.append('path',this.pathHash);
  }


  
  validateExt(file:File):boolean{
    let extension = file.name.split('.').pop()!.toLowerCase();
    console.log("extension:["+extension);
    if(this.fileReq.path.includes("Documentos")){
      if(this.allowedDocs.includes(extension)){
          return true;
      }else{
        this.clearForm();
        Swal.fire({
          title: "Archivo Invalido",
          text: "debes incluir archivos con extensión: ["+this.allowedDocs+"]",
          icon: "warning",
          confirmButtonColor: '#014FA2',
        });
      } 
    }else if(this.fileReq.path.includes("Imagenes")){
      if(this.allowedImages.includes(extension)){
        return true;
      }else{
        this.clearForm();
        Swal.fire({
          title: "Archivo Invalido",
          text: "Debes incluir archivos con extensión: ["+this.allowedImages+"]",
          icon: "warning",
          confirmButtonColor: '#014FA2',
        });
      } 
    }else if(this.fileReq.path.includes("Videos")){
      if(this.allowedVideos.includes(extension)){
        return true;
      }else{
        this.clearForm();
        Swal.fire({
          title: "Archivo Invalido",
          text: "Debes incluir archivos con extensión: ["+this.allowedVideos+"]",
          icon: "warning",
          confirmButtonColor: '#014FA2',
        });
      }    
    }
    return false;
  }

  closeModalService(){
    this.modalService.dismissAll();
  }

  clearForm() { 
    this.productoForm.reset({
      'archivo': ''
    });
  }

  handleError(error: any) {
    console.log(error);
    return throwError(error || 'Server error');
  }

} 
