import { Injectable } from '@angular/core';
import { ConsumeService } from './consume.service';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(private consume:ConsumeService,) { }



  putFiles(formData:FormData){
    this.consume.putFile(formData).subscribe(response=>{
       console.log("response: ["+response+"]"); 
       return response;
    });
  }


}
