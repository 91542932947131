import { Inject, Injectable, Injector, Type } from '@angular/core';
import { NavigationService } from './navigation.service';
import { Subscription } from 'rxjs';
import { Route, Router, Routes } from '@angular/router';
import { HomeComponent } from '../components/home/home.component';
import { UserComponent } from '../components/user/user.component';
import { MEDIA_ROUTES } from '../components/media/media.routes';
import { ErrorComponent } from '../components/error/error.component';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { ConsumeService } from './consume.service';
import { RoleBaseGuard } from '../guard/role-base.guard';
import { LoginComponent } from '../components/login/login/login.component';
import { RecoverypassComponent } from '../components/login/recoverypass/recoverypass.component';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  url:string="";
  private siteMapsUrlsSubscription! : Subscription; 
  LISTA_CARPETAS:string []=[];
  ms = 1000;
  pathname="";
  constructor( 
      private injector: Injector, 
      private navigationService: NavigationService,
      private consume:ConsumeService,
    @Inject(DOCUMENT) document: any) { 
    
    console.log("::::::::::: SettingsService ::::::::::::");
    console.log(document.location.href);
    this.pathname=window.location.hash;
    console.log("pathname:["+this.pathname+"]");
  } 

 

   loadSettings() { 
    console.log("------- loadSettings --------");
    console.log(document.location.href);
      let result=   new Promise((resolve, reject) => { 
        let arrayT:string []=[];
        return this.consume.getLstCarpetas().subscribe((response)=>{
          console.log("response:::>"+response.arrayFolders);
          if(response.mensaje.codigo ===200){
              const array:string= response.arrayFolders
              for(let a =0; a < array.length;a++){
                arrayT.push(array[a]);
              }
              this.LISTA_CARPETAS= arrayT;
              const router = this.injector.get(Router);
              this.siteMapsUrlsSubscription = this.navigationService.getSiteMapUrls2().subscribe({ 
                    next: (sitemap) => {
                    //this.setSettingsRouter(router, sitemap.sitemapItems); 
                    this.setSettingsRouter(router); 
                    resolve("OK"); 
                  } 
              });  
          }else{
              console.log("No se pudo obtener la lista de carpetas.");
          } 
        });
     }); 
     return result;
  } 
    
  private setSettingsRouter(router: Router){
    console.log("-------- setSettingsRouter --------");
      //const basicRoutes: Routes = [ { path: 'principal', component: HomeComponent, children:MEDIA_ROUTES } ]; 
      //let routerItems: Routes=[]; 
      const usuario=JSON.parse(localStorage.getItem("usuario")!);
      console.log("usuario:["+usuario+"]");
      if(usuario && usuario.rol==="ADMIN"){
         router.navigate(['/principal']);
         localStorage.setItem("login","isNotLogin");
      }else{
          this.routerUser();  
         //routerItems.concat(basicRoutes);
      }
     
      //router.config = routerItems; 
      //router.config = routerItems.concat(basicRoutes); 

     // console.log("router:["+router.config+"]");
     router.config.forEach(a=>console.log(a));
  } 

  private  routerUser(){ 
        console.log("--- routerUser  ----");
      
        let componentToRoute: any; 
        let _encontrado=false;
        let carpeta= this.pathname.replace("#/principal/","");
        const router = this.injector.get(Router); 
        let routerItems: Routes=[];
        console.log("---  this.LISTA_CARPETAS   ----["+this.LISTA_CARPETAS+"]");
        this.LISTA_CARPETAS.forEach(carpetaValida=>{
                console.log("carpetaValida:["+carpetaValida+"] === carpetaEnURL:["+carpeta+"]");
                //const urlContainsFoo = carpeta.includes(carpetaValida);
                //console.log(urlContainsFoo); 
                if(carpeta === carpetaValida){
                    //localStorage.setItem("login","isNotLogin");
                    
                    componentToRoute = { path: "principal/"+carpetaValida, component: UserComponent,  children:MEDIA_ROUTES };
                    routerItems.push(componentToRoute);
                    
                    /*
                    componentToRoute =  { path: '**',component: ErrorComponent };
                    */
                    _encontrado=true;
                }
        });

        /*
        if(_encontrado){
            this.LISTA_CARPETAS.forEach(carpetaValida=>{
              console.log("carpetaValida:["+carpetaValida+"] === carpetaEnURL:["+carpeta+"]");
              componentToRoute = { path: "principal/"+carpetaValida, component: UserComponent,  children:MEDIA_ROUTES };
              routerItems.push(componentToRoute);  
            });
        }
        */
        console.log("encontrado:["+_encontrado+"]");
        if(!_encontrado){
              const urlContainsFoo =      carpeta.includes("login");
              const urlContainsRecovery = carpeta.includes("recovery");
              //localStorage.setItem("login","isLogin");
              if(urlContainsFoo || urlContainsRecovery){
                
              }else{
                //componentToRoute =  { path: '**',component: ErrorComponent };
                //routerItems.push(componentToRoute);
              }
        }

        let componentToLogin =     { path: 'login',component: LoginComponent } ;
        let componentToRecovery =  { path: 'recovery',component: RecoverypassComponent };
        let componentToError =     { path: '**',component: ErrorComponent };
        
        
        routerItems.push(componentToLogin);
        routerItems.push(componentToRecovery);
        routerItems.push(componentToError);

        router.config = routerItems;
  }
 

  public routerAdmin(path:string,paso:number){
    console.log("--- routerAdmin paso["+paso+"] ----");
    console.log("PATH-->"+path);
    const router = this.injector.get(Router); 
    let routerItems: Routes=[];
    
    if(paso === 1){

        let componentToRoute = { 
            path: path, 
            component: HomeComponent,  
            children:MEDIA_ROUTES , canActivate:[ RoleBaseGuard],
            data: {
                  role: 'ADMIN'
        }};

        routerItems.push(componentToRoute);

    }else  if(paso === 2){

      let componentToRoute =     { path: path, component: UserComponent,  children:MEDIA_ROUTES};
     
      let componentToRoute1 = { 
          path: "principal", 
          component: HomeComponent,  
          children:MEDIA_ROUTES , 
          canActivate:[ RoleBaseGuard],
          data: {
                role: 'ADMIN'
          }
      };

      routerItems.push(componentToRoute);
      routerItems.push(componentToRoute1);
        
    }  

    let componentToLogin =     { path: 'login',component: LoginComponent } ;
    let componentToRecovery =  { path: 'recovery',component: RecoverypassComponent };
    let componentToError =     { path: '**',component: LoginComponent };

    routerItems.push(componentToLogin)
    routerItems.push(componentToRecovery)
    routerItems.push(componentToError)
    
    router.config = routerItems;
    router.config.forEach(a=>console.log(a));
   
  }

  



}

export function initSettings(settings: SettingsService) { 
  return (): Promise<any> => { 
   return settings.loadSettings(); 
  } 
} 