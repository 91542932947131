import { ChangeDetectorRef, Component,EventEmitter, OnInit, ViewChild } from '@angular/core';
import { ConsumeService } from 'src/app/services/consume.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, startWith } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  dataSource = new MatTableDataSource<File>();
  _isAdmin=false;
  documentos=true;
  imagenes=true;
  videos=true;
  activaRetorno=false;
  //documentos: Subject<boolean>=new Subject();
  //documentos:Subject<boolean>=new Subject();
  //imagenes:Subject<boolean>=new Subject();
  //videos:Subject<boolean>=new Subject();
  //imagenes = new BehaviorSubject<boolean>(true);
  //videos = new BehaviorSubject<boolean>(true);
  //documentos = new BehaviorSubject<boolean>(true);
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  mediaFolder="";
  rutaPrincipal="";
  carpetaActual="";
  rol="";

  Breakpoints = Breakpoints;
  currentBreakpoint:string = '';
  
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, '(min-width: 1366px)'])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );

  constructor(private consumeService: ConsumeService,private router: Router,
              private ref: ChangeDetectorRef, private userService: UserService,
              private breakpointObserver: BreakpointObserver) { 
    console.log(":::::::::::::::::::::::::::::::::::");
    console.log(":::::::::::::::::: UserComponent ::::::::::::::::::::");
    console.log(":::::::::::::::::::::::::::::::::::");
    
    this.userService.setPathLogin(false);
    this.userService.setCantUploadFile(false);

    this.userService.mediaFolder.subscribe(carpetaStatus=>{
          console.log("regresando de --->["+carpetaStatus+"]");
          if(carpetaStatus === "imagenes"){
            this.documentos=false;
            this.videos=false;
          }else if(carpetaStatus === "documentos"){
              this.imagenes=false;
              this.videos=false;
          }else if(carpetaStatus === "videos"){
              this.imagenes=false;
              this.documentos=false;
          }
          this.activaRetorno=true;
          console.log("documentos activo-->["+ this.documentos+"]");
          console.log("videos activo    -->["+ this.videos+"]");
          console.log("imagenes activo  -->["+ this.imagenes+"]");
    });

  
   
  
    console.log("RUTA PRINCIPAL:["+localStorage.getItem("rutap")+"]");

    //this.rutaPrincipal=this.router.url;
    //console.log("RUTA ACTIVA:["+this.rutaPrincipal+"]");
   
    console.log(" this.carpetaActual:["+ this.carpetaActual+"]");
    this.rol=localStorage.getItem("rol")!;
    if(this.rol === "ADMIN"){
        if(localStorage.getItem("rutap") === null){
          localStorage.setItem("rutap",this.router.url);
        } 
        this.carpetaActual=localStorage.getItem("rutap")!.replace("/principal/","");
        this._isAdmin=true;
    }else{
        this.carpetaActual=this.router.url.replace("/principal/","");
    }
    
  }

  
  private breakpointChanged() {
    if(this.breakpointObserver.isMatched(Breakpoints.Large)) {
      this.currentBreakpoint = Breakpoints.Large;
    } else if(this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.currentBreakpoint = Breakpoints.Medium;
    } else if(this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.currentBreakpoint = Breakpoints.Small;
    } else if(this.breakpointObserver.isMatched('(min-width: 1366px)')) {
      this.currentBreakpoint = '(min-width: 500px)';
    }
  }


  ngOnChanges() {
    console.log("ngOnChanges");
  }

  ngOnInit(): void {
    console.log("====== ngOnInit =======");

    this.breakpoint$.subscribe(() =>
      this.breakpointChanged()
    );

    this.documentos=true;
    this.videos=true;
    this.imagenes=true;
    console.log("documentos activo-->["+ this.documentos+"]");
    console.log("videos activo    -->["+ this.videos+"]");
    console.log("imagenes activo  -->["+ this.imagenes+"]");
  }


  regresar(){
    this.router.navigate(["principal"]);
  }

  regresarUser(){
    this.documentos=true;
    this.videos=true;
    this.imagenes=true;
    this.activaRetorno=false;
    this.userService.setCantUploadFile(false);
    if(this.rol === "ADMIN"){
      console.log("regresar a -->:["+localStorage.getItem("rutap")+"]");
      this.router.navigate([localStorage.getItem("rutap")]);
    }else{
      console.log("regresar a -->:["+this.carpetaActual+"]");
      this.router.navigate(["principal/"+this.carpetaActual]);
    }
  }



  getFolderSelect(carpeta:string){
    this.userService.getStatusFolder(carpeta);
    console.log("carpeta:["+carpeta+"]");
  }


}
